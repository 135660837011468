import React, { useState } from "react";
// import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";
import TaxPlanningForm from "../components/Form/TaxPlanning";

const Page = ({ data }) => {
  const [setChoices, setChoicesState] = useState("block");
  const [setGetStarted, setGetStartedState] = useState("hidden");
  const [setPlan, setPlanState] = useState("");

  function showGetStarted(plan) {
    setGetStartedState("block");
    setChoicesState("hidden");
    setPlanState(plan);
  }

  function goBack2() {
    setChoicesState("block");
    setGetStartedState("hidden");
  }

  return (
    <Layout>
      <SearchEngineOptimization
        title="Financial Plans | DeBlanc + Murphy"
        description="Craft your financial future with DeBlanc Murphy. We offer personalized financial plans for businesses & individuals. Get started today!"
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section>
        <div className="container">
          <div className={`pb-20 pt-10 lg:pb-32 lg:pt-16 ${setChoices}`}>
            <header className="mb-12 max-w-[720px] lg:mb-16">
              <h1>Financial Plans</h1>
              <p className="lg:text-xl">
                Achieve your goals, be prepared when the unpredictable happens,
                and establish financial security.
              </p>
            </header>

            <div className="grid gap-y-10 lg:grid-cols-3 lg:gap-x-8">
              <div className="relative rounded-3xl border border-primary-500 bg-gray-800 px-10 pb-14 pt-14 lg:pb-32">
                <div className="heading-three text-center font-normal text-primary-500">
                  One-time Personal Financial Plan
                </div>
                <div className="heading-three mb-5 text-center text-white">
                  $3,000
                </div>

                <div className="mb-3.5 text-sm font-light uppercase tracking-wide">
                  Includes:
                </div>
                <ul className="mb-6 grid grid-cols-2 gap-x-4">
                  <div className="space-y-1.5">
                    <li className="flex items-center space-x-2">
                      <i className="fa-regular fa-check"></i>
                      <span className="text-xs">Taxes</span>
                    </li>
                    <li className="flex items-center space-x-2">
                      <i className="fa-regular fa-check"></i>
                      <span className="text-xs">Income</span>
                    </li>
                    <li className="flex items-center space-x-2">
                      <i className="fa-regular fa-check"></i>
                      <span className="text-xs">Expenses</span>
                    </li>
                    <li className="flex items-center space-x-2">
                      <i className="fa-regular fa-check"></i>
                      <span className="text-xs">Debt</span>
                    </li>
                    <li className="flex items-center space-x-2">
                      <i className="fa-regular fa-check"></i>
                      <span className="text-xs">Insurance</span>
                    </li>
                  </div>
                  <div className="space-y-1.5">
                    <li className="flex items-center space-x-2">
                      <i className="fa-regular fa-check"></i>
                      <span className="text-xs">Retirement</span>
                    </li>
                    <li className="flex items-center space-x-2">
                      <i className="fa-regular fa-check"></i>
                      <span className="text-xs">College Planning</span>
                    </li>
                    <li className="flex items-center space-x-2">
                      <i className="fa-regular fa-check"></i>
                      <span className="text-xs">Estate Planning</span>
                    </li>
                    <li className="flex items-center space-x-2">
                      <i className="fa-regular fa-check"></i>
                      <span className="text-xs">Investments</span>
                    </li>
                    <li className="flex items-center space-x-2">
                      <i className="fa-regular fa-check"></i>
                      <span className="text-xs">
                        Lump-sum Purchase Planning
                      </span>
                    </li>
                  </div>
                </ul>

                <div className="mb-10 flex items-start space-x-2 lg:mb-0">
                  <i className="fa-regular fa-xmark text-lg"></i>
                  <span className="text-xs">
                    Execution and follow on assistance available on an hourly
                    basis
                  </span>
                </div>

                <div className="lg:absolute lg:bottom-12 lg:left-10 lg:right-10">
                  <ButtonSolid
                    text="Act Now"
                    onClick={() =>
                      showGetStarted("One-time Personal Financial Plan")
                    }
                    className="w-full"
                  />
                </div>
              </div>

              <div className="relative rounded-3xl border border-primary-500 bg-gray-800 px-10 pb-14 pt-14 lg:pb-32">
                <div className="heading-three text-center font-normal text-[#25BC74]">
                  Personal Financial Planning Only
                </div>
                <div className="heading-three mb-5 text-center text-white">
                  $3,000 <span className="text-2xl font-normal">+</span> $299
                  <span className="text-2xl font-normal">/mo.</span>
                </div>

                <div className="mb-3.5 text-sm font-light uppercase tracking-wide">
                  Includes:
                </div>
                <ul className="mb-8 space-y-1.5 md:mb-0">
                  <li className="flex items-center space-x-2">
                    <i className="fa-regular fa-check"></i>
                    <span className="text-xs">Financial Plan</span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <i className="fa-regular fa-check"></i>
                    <span className="text-xs">
                      Investment Planning and Allocation Recommendation
                    </span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <i className="fa-regular fa-check"></i>
                    <span className="text-xs">Quarterly Review Meetings</span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <i className="fa-regular fa-check"></i>
                    <span className="text-xs">Annual Plan Review</span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <i className="fa-regular fa-check"></i>
                    <span className="text-xs">
                      Ongoing, On-Demand Financial Plan Support
                    </span>
                  </li>
                </ul>

                <div className="lg:absolute lg:bottom-12 lg:left-10 lg:right-10">
                  <ButtonSolid
                    text="Start Today"
                    onClick={() =>
                      showGetStarted("Personal Financial Planning Only")
                    }
                    className="w-full bg-[#25BC74]"
                  />
                </div>
              </div>

              <div className="relative rounded-3xl border border-primary-500 bg-gray-800 px-10 pb-14 pt-14 lg:pb-32">
                <div className="heading-three text-center font-normal text-[#60ACF7]">
                  Full Wealth Management
                </div>
                <div className="heading-three mb-5 text-center text-white">
                  $3,000 <span className="text-2xl font-normal">+</span> 1%
                </div>

                <div className="mb-3.5 text-sm font-light uppercase tracking-wide">
                  Includes:
                </div>
                <ul className="mb-8 space-y-1.5 lg:mb-0">
                  <li className="flex items-center space-x-2">
                    <i className="fa-regular fa-check"></i>
                    <span className="text-xs">Financial Plan</span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <i className="fa-regular fa-check"></i>
                    <span className="text-xs">
                      Investment Planning and Allocation Recommendation
                    </span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <i className="fa-regular fa-check"></i>
                    <span className="text-xs">Open Investments Accounts</span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <i className="fa-regular fa-check"></i>
                    <span className="text-xs">
                      Discretion to Trade on Your Behalf
                    </span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <i className="fa-regular fa-check"></i>
                    <span className="text-xs">Propose Asset Allocation</span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <i className="fa-regular fa-check"></i>
                    <span className="text-xs">Quarterly Review Meetings</span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <i className="fa-regular fa-check"></i>
                    <span className="text-xs">
                      Annual Comprehensive Plan Review
                    </span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <i className="fa-regular fa-check"></i>
                    <span className="text-xs">
                      Ongoing, on-demand Financial Plan Support
                    </span>
                  </li>
                </ul>

                <div className="lg:absolute lg:bottom-12 lg:left-10 lg:right-10">
                  <ButtonSolid
                    text="Begin the Path"
                    onClick={() => showGetStarted("Full Wealth Management")}
                    className="w-full bg-[#60ACF7]"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={`pb-20 pt-10 lg:pb-32 lg:pt-10 ${setGetStarted}`}>
            <button
              className="text-primary-900 group mb-10 flex items-center space-x-2 no-underline hover:text-primary-500"
              type="button"
              onClick={goBack2}
            >
              <i className="far fa-arrow-left text-xl"></i>
              <span className="font-bold">Go Back</span>
            </button>

            <div className="mx-auto max-w-[640px]">
              <header className="mb-12 text-center lg:mb-16">
                <p className="heading-one">Get Started</p>
                <p className="lg:text-lg">
                  Please provide your contact information and a DeBlanc + Murphy
                  team member will be in touch with you shortly.
                </p>
              </header>
              <TaxPlanningForm plan={setPlan} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

// export const query = graphql`
//   {
//     openGraphImage: file(
//       relativePath: { eq: "open-graph/facebook/Global.jpg" }
//     ) {
//       publicURL
//     }
//     twitterOpenGraphImage: file(
//       relativePath: { eq: "open-graph/twitter/Global.jpg" }
//     ) {
//       publicURL
//     }
//   }
// `;

export default Page;
